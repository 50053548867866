
/**
 * Modal that show the current image for the camera section
 *
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Section from "@/model/ModelSection";

import { mapGetters, mapActions } from "vuex";
import { AxiosResponse } from "axios";
import Project from "@/model/Project";
import { ActionMessage } from "@/model/Messages/statusMessage";

interface CameraSettings {
  id: number;
  cameraId: number | null;
}

@Component({
  name: "cameraSectionImageModal",
  computed: {
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      GET_CURRENT_PICTURE: "GetCurrentPicture",
      GET_CAMERA_SECTION_SETTINGS: "GetCurrentCameraSectionSettings"
    })
  },
  methods: {
    ...mapActions("projects", {
      GET_CAMERA_IDENTIFIER: "GetCameraSectionIdentifier",
      GET_CAMERA_LATEST_PICTURE: "GetCameraSectionLatestPicture"
    })
  }
})
export default class cameraSectionImageModal extends Vue {
  // Vuex
  @Prop({ type: Object }) sectionInfo!: Section;
  readonly GET_CURRENT_PICTURE!: any;
  readonly GET_CAMERA_SECTION_SETTINGS!: CameraSettings;
  readonly currentProject!: Project;

  private GET_CAMERA_LATEST_PICTURE!: Function;
  private GET_CAMERA_IDENTIFIER!: Function;

  mainProps: any = {
    center: true,
    fluidGrow: true,
    blank: true,
    blankColor: "#eee",
    width: 600,
    height: 400,
    class: "my-5"
  };
  localCameraSettings: CameraSettings | null = null;
  getImageStatus: null | ActionMessage = null;
  updateImageInterval: Array<any> = [];

  async created() {
    await this.GET_CAMERA_IDENTIFIER(this.sectionInfo.id);
  }

  beforeDestroy() {
    console.log("#### CLEAN INTERVAL IMG ######");
    this.clearAllIntervalsBeforeLeave();
  }
  mounted() {
    this.updateCameraImageEveryMinute();
  }

  @Watch("GET_CAMERA_SECTION_SETTINGS", { immediate: false })
  formatCameraSectionSettings() {
    this.localCameraSettings = {
      id: this.GET_CAMERA_SECTION_SETTINGS.id,
      cameraId: this.GET_CAMERA_SECTION_SETTINGS.cameraId === -1 ? null : this.GET_CAMERA_SECTION_SETTINGS.cameraId
    };

    this.getLatestImageFromCameraSection();
  }

  private async clearAllIntervalsBeforeLeave() {
    let promises = this.updateImageInterval.map(async (intervalId: number) => {
      await clearInterval(intervalId);
    });
    await Promise.all(promises);
  }

  async getLatestImageFromCameraSection() {
    this.getImageStatus = null;
    if (this.localCameraSettings?.cameraId) {
      await this.GET_CAMERA_LATEST_PICTURE(this.localCameraSettings.cameraId).then((res: AxiosResponse) => {
        if (res.status !== 200) {
          this.getImageStatus = {
            class: "danger",
            msg: this.$t("project.schematicView.cameraSection.cameraNoPictureAvailable").toString()
          };
        }
      });
    }
  }

  updateCameraImageEveryMinute() {
    if (this.currentProject.isArchived) return;
    else {
      let intervalTime = 1000 * 60;
      let newInterval = setInterval(async () => {
        console.log("####### image Interval #####");
        if (this.localCameraSettings && this.localCameraSettings.cameraId) {
          let getLatestImage = await this.GET_CAMERA_LATEST_PICTURE(this.localCameraSettings.cameraId);
          if (!getLatestImage || getLatestImage.status !== 200) {
            this.getImageStatus = {
              class: "danger",
              msg: this.$t("project.schematicView.cameraSection.cameraNoPictureAvailable").toString()
            };
          }
        }
      }, intervalTime);

      this.updateImageInterval.push(newInterval);
    }
  }
}
