
/**
 * Modal that show the current image for the camera section
 *
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Section from "@/model/ModelSection";
import { mapGetters, mapActions } from "vuex";
import { ActionMessage } from "@/model/Messages/statusMessage";
import Project from "@/model/Project";

interface CameraSettings {
  id: number;
  cameraId: number | null;
}

@Component({
  name: "cameraSectionImageModal",
  computed: {
    ...mapGetters("projects", {
      GET_CAMERA_SECTION_SETTINGS: "GetCurrentCameraSectionSettings",
      currentProject: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions("projects", {
      GET_CAMERA_IDENTIFIER: "GetCameraSectionIdentifier",
      SET_CAMERA_IDENTIFIER: "UpdateCameraIdentifier"
    })
  }
})
export default class cameraSectionImageModal extends Vue {
  // Vuex
  @Prop({ type: Object }) sectionInfo!: Section;
  private SET_CAMERA_IDENTIFIER!: Function;
  private GET_CAMERA_IDENTIFIER!: Function;

  private readonly currentProject!: Project;
  private readonly GET_CAMERA_SECTION_SETTINGS!: CameraSettings;

  localCameraSettings: CameraSettings | null = null;
  cameraSettingsUpdateStatus: null | ActionMessage = null;
  created() {
    this.GET_CAMERA_IDENTIFIER(this.sectionInfo.id);
  }
  @Watch("GET_CAMERA_SECTION_SETTINGS", { immediate: false })
  formatCameraSectionSettings() {
    this.localCameraSettings = {
      id: this.GET_CAMERA_SECTION_SETTINGS.id,
      cameraId: this.GET_CAMERA_SECTION_SETTINGS.cameraId === -1 ? null : this.GET_CAMERA_SECTION_SETTINGS.cameraId
    };
  }

  confirmModal(msg: string, action: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("project.section.displayInterval.confirmModals.title"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        action(value);
      });
  }

  async handleCameraSettingsUpdate() {
    this.cameraSettingsUpdateStatus = null;
    this.confirmModal(
      (this as any).$t("project.schematicView.cameraSection.cameraConfirmModalText"),
      async (value: boolean) => {
        if (value) {
          const cameraUpdate = await this.SET_CAMERA_IDENTIFIER({
            cameraSettings: this.localCameraSettings,
            sectionId: this.sectionInfo.id
          });

          if (cameraUpdate.status === 200) {
            this.cameraSettingsUpdateStatus = {
              class: "success",
              msg: (this as any).$t("project.schematicView.cameraSection.cameraUpdateSuccessMsg")
            };
          } else if (cameraUpdate.status === 400) {
            this.cameraSettingsUpdateStatus = {
              class: "warning",
              msg: (this as any).$t("archiveProjects.actions.action400res")
            };
          } else {
            this.cameraSettingsUpdateStatus = {
              class: "danger",
              msg: cameraUpdate
            };
          }
        } else {
          this.GET_CAMERA_IDENTIFIER(this.sectionInfo.id);
        }
      }
    );
  }
  async handleResetCameraIdentifier() {
    this.cameraSettingsUpdateStatus = null;
    this.confirmModal(
      (this as any).$t("project.schematicView.cameraSection.cameraConfirmModalResetText"),
      async (value: boolean) => {
        if (value && this.localCameraSettings) {
          const cameraUpdate = await this.SET_CAMERA_IDENTIFIER({
            cameraSettings: {
              id: this.localCameraSettings.id,
              cameraId: -1
            },
            sectionId: this.sectionInfo.id
          });

          if (cameraUpdate.status === 200) {
            this.cameraSettingsUpdateStatus = {
              class: "success",
              msg: (this as any).$t("project.schematicView.cameraSection.cameraConfirmModalResetSuccessMsg")
            };
          } else if (cameraUpdate.response.status === 400) {
            this.cameraSettingsUpdateStatus = {
              class: "warning",
              msg: (this as any).$t("archiveProjects.actions.action400res")
            };
          } else {
            this.cameraSettingsUpdateStatus = {
              class: "danger",
              msg: cameraUpdate
            };
          }
        } else {
          this.GET_CAMERA_IDENTIFIER(this.sectionInfo.id);
        }
      }
    );
  }
}
